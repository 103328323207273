import { useState } from 'react';
import '../component_styling/Projects.css';

function Projects() {
    return (
        <section className='project-page' id='projects'>
            <div className='section-header'><p>Recent Projects</p></div>


            <div className='project2-color'>
                <div className='project-space-container'>
                    <div className='project-left-opposite'>
                        <div className='project-title-opposite'><h3>Alfie's Smoked Salsa</h3></div>
                        <div className='project-text-opposite'><p>Alfie's Smoked Salsa LLC is a company that sells fresh smoked salsa. They were looking to set up an online store so that their delicious salsa could reach more customers. Together we built Alfie's Smoked Salsa to give them the opportunity to sell online as well as deliver local orders.</p></div>
                        {/* <div className='project-tools-title-opposite'><p>Tools -</p></div> */}
                        <div className='project-tools'>
                            <ul className='arrow-list-opposite'>
                                <li>Responsive Web Design</li>
                                <li>Online Store</li>
                                <li>Photoshop</li>
                                <li>Wix</li>
                                <li>UPS Plugin Integration</li>
                            </ul>
                        </div>
                        <div className='view-project-opposite'><a href='https://www.alfiessmokedsalsa.com/' target='_blank' rel='noopener noreferrer'>View Project</a></div>
                    </div>
                    <div className='project-right'>
                        <div className='project1-showcase'></div>
                    </div>
                </div>
            </div>

            <div className='project1-color'>
                <div className='project-space-container'>
                    <div className='project-left'>
                        <div className='project-title'><h3>Hope In Your Journey</h3></div>
                        <div className='project-text'><p>Natalie Ringgenberg is a womens life coach. She was looking to build a website to reach more clients and post her writing for others to easily access. Together we built Hope In Your Journey a place for women who are dealing with divorce, cancer and the everyday stresses of life to be able to overcome their circumstances.</p></div>
                        {/* <div className='project-tools-title'><p>Tools -</p></div> */}
                        <div className='project-tools'>
                            <ul className='arrow-list'>
                                <li>Responsive Web Design</li>
                                <li>Photoshop</li>
                                <li>WordPress</li>
                                <li>Blog</li>
                            </ul>
                        </div>
                        <div className='view-project'><a href='https://hopeinyourjourney.com/' target='_blank' rel='noopener noreferrer'>View Project</a></div>
                    </div>
                    <div className='project-right'>
                        <div className='project2-showcase'></div>
                    </div>
                </div>
            </div>

            <div className='project2-color'>
                <div className='project-space-container'>
                    <div className='project-left-opposite'>
                        <div className='project-title-opposite'><h3>Elite Canine Fitness</h3></div>
                        <div className='project-text-opposite'><p>Kyle Fuqua is an animal fitness business owner. He has been in business for over 13 years. He was looking to build a landing page for his second business. Together we built Elite Canine Fitness a place for potiential clients to land and see what benefits Kyle could offer them. </p></div>
                        {/* <div className='project-tools-title-opposite'><p>Tools -</p></div> */}
                        <div className='project-tools'>
                            <ul className='arrow-list-opposite'>
                                <li>Responsive Web Design</li>
                                <li>Media Queries</li>
                                <li>Photoshop</li>
                                <li>Landing Page</li>
                                <li>HTML5</li>
                                <li>CSS</li>
                            </ul>
                        </div>
                        <div className='view-project-opposite'><a href='https://elitecaninefitness.com/' target='_blank' rel='noopener noreferrer'>View Project</a></div>
                    </div>
                    <div className='project-right'>
                        <div className='project3-showcase'></div>
                    </div>
                </div>
            </div>


            <div className='project1-color'>
                <div className='project-space-container'>
                    <div className='project-left'>
                        <div className='project-title'><h3>DoorDash <br />Promotional Email</h3></div>
                        <div className='project-text'><p>A well designed email with light and dark mode capability. This email accomplishes its mobile friendly design with the use of media queries.</p></div>
                        {/* <div className='project-tools-title'><p>Tools -</p></div> */}
                        <div className='project-tools'>
                            <ul className='arrow-list'>
                                <li>Tables</li>
                                <li>Media Queries</li>
                                <li>Light and Dark Mode</li>
                                <li>Email On Acid</li>
                            </ul>
                        </div>
                        <div className='view-project'><a href={`${process.env.PUBLIC_URL}/doordash-promotional/doordash.html`} target='_blank' rel='noopener noreferrer'>View Project</a></div>
                    </div>
                    <div className='project-right'>
                        <div className='project4-showcase'></div>
                    </div>
                </div>
            </div>
            <div className='project2-color'>
                <div className='project-space-container'>
                    <div className='project-left-opposite'>
                        <div className='project-title-opposite'><h3>Mattress Firm<br /> Transactional Email</h3></div>
                        <div className='project-text-opposite'><p>This is a transactional email from Mattress firm that confirms a recent purchase. It uses fluid hybrid design to achive the correct sizing on all devices.</p></div>
                        {/* <div className='project-tools-title-opposite'><p>Tools -</p></div> */}
                        <div className='project-tools'>
                            <ul className='arrow-list-opposite'>
                                <li>Tables</li>
                                <li>Fluid Hybrid Design</li>
                                <li>Inline CSS</li>
                                <li>Email On Acid</li>
                            </ul>
                        </div>
                        <div className='view-project-opposite'><a href={`${process.env.PUBLIC_URL}/mattressfirm-transactional/mattressfirm.html`} target='_blank' rel='noopener noreferrer'>View Project</a></div>
                    </div>
                    <div className='project-right'>
                        <div className='project5-showcase'></div>
                    </div>
                </div>
            </div>
            <div className='project1-color'>
                <div className='project-space-container'>
                    <div className='project-left'>
                        <div className='project-title'><h3>Teachable<br /> Newsletter Email</h3></div>
                        <div className='project-text'><p>This email features a colorful design and is my personal favorite. I also appreciate how every call to action is linked to the appropriate place, this made navigation very easy.</p></div>
                        {/* <div className='project-tools-title'><p>Tools -</p></div> */}
                        <div className='project-tools'>
                            <ul className='arrow-list'>
                                <li>Tables</li>
                                <li>Fluid Hybrid Design</li>
                                <li>Inline CSS</li>
                                <li>Email On Acid</li>
                            </ul>
                        </div>
                        <div className='view-project'><a href={`${process.env.PUBLIC_URL}/teachable-newsletter/teachable.html`} target='_blank' rel='noopener noreferrer'>View Project</a></div>
                    </div>
                    <div className='project-right'>
                        <div className='project6-showcase'></div>
                    </div>
                </div>
            </div>

            
        </section>
    );
}

export default Projects